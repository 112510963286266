/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { inheritFonts, inheritWidth, width100 } from '../../style/genericStyling';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../style/basicStyle';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { PasswordForgetLink } from '../PasswordForget';
import { SignUpLink } from '../SignUp';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    const { message1 } = props;
    const { message2 } = props;
    const { redirect } = props;
    INITIAL_STATE.message1 = message1;
    INITIAL_STATE.message2 = message2;
    INITIAL_STATE.redirect = redirect || ROUTES.HOME;
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password, redirect } = this.state;
    const { props } = this;
    const { noRedirect } = props;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        !noRedirect && navigate(redirect);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, message1, message2, redirect } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <Flex textAlign="center" justifyContent="center" wrap={['wrap']}>
        <Box m={[2, 2, 2, 2]} py={[4, 5]} textAlign="center" width={[1, 7/10, 500]} fontSize={[4, 4, 4]}>
          <Container width={[1]}>
            <h3>{message1 || <>Sign In</>} </h3>
            {message2 && <Box my={[3]}>{message2}</Box>}
            <form css={[inheritFonts, width100]} onSubmit={this.onSubmit}>
              <Box p={[1]} css={[inheritWidth, inheritFonts]}>
                <input css={[inheritFonts, inheritWidth]} name="email" value={email} autoComplete="email" onChange={this.onChange} type="text" placeholder="Email Address" />
              </Box>
              <Box p={[1]} css={[inheritWidth, inheritFonts]}>
                <input css={[inheritFonts, inheritWidth]} name="password" value={password} autoComplete="current-password" onChange={this.onChange} type="password" placeholder="Password" />
              </Box>
              <Box p={[1]} css={[inheritWidth, inheritFonts]}>
                <button css={[inheritFonts, inheritWidth]} disabled={isInvalid} type="submit">
                  Sign In
                </button>
              </Box>
              <Box p={[1]} fontSize={[4, 4, 4]}>
                {error && <p>{error.message}</p>}
              </Box>
            </form>
            <Box width={[1]}>
              <Box textAlign="left" css={[inheritFonts]}>
                <PasswordForgetLink />
              </Box>
              <Box textAlign="left" css={[inheritFonts]}>
                <SignUpLink redirect={redirect} />
              </Box>
            </Box>
          </Container>
        </Box>
      </Flex>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {}
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {}
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {}
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withFirebase(SignInFormBase);

const SignInGoogle = withFirebase(SignInGoogleBase);

const SignInFacebook = withFirebase(SignInFacebookBase);

const SignInTwitter = withFirebase(SignInTwitterBase);

export default SignInForm;

export { SignInGoogle, SignInFacebook, SignInTwitter };
