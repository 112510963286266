import React from 'react';
import styledSystem from '@emotion/styled';
import { graphql } from 'gatsby';
import { Box, Flex } from '../style/basicStyle';
import LayoutWithAuth from '../components/layoutWithAuth';
import SignInForm, { SignInGoogle, SignInFacebook, SignInTwitter } from '../components/SignIn';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';

export const SignInPageOLD = () => (
  <>
    <h1>SignIn</h1>
    <SignInForm />
    <SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordForgetLink />
    <SignUpLink />
  </>
);

// eslint-disable-next-line react/prop-types
export default ({ location }) => {
  // eslint-disable-next-line react/prop-types
  const redirect = location && location.state && location.state.redirect;
  return (
    <Box py={[5]}>
      <LayoutWithAuth>
        <SignInForm redirect={redirect} />
      </LayoutWithAuth>
    </Box>
  );
};

export const pageQuery = graphql`
  query signinContentQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/signinHero/" } }) {
      fluid(maxWidth: 1500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "signin" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
